.switch {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  white-space: nowrap;
}

.label {
  font-size: 16px;
  color: #000;
}

.tick {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  width: 22px;
  height: 22px;
}

.tick::after {
  transform: rotate(-45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-bottom: 4px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  width: 12px;
  height: 6px;
  content: '';
}

.switch[aria-checked='true'] > .tick::after {
  opacity: 1;
}

.switch[aria-checked='true'] > .tick {
  background-color: #000;
}
