@value min-medium from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 668px;
  max-height: 100dvh;
}

@media min-medium {
  .wrapper {
    min-width: 400px;
    height: 100%;
  }
}

.error {
  color: red;
}

.termsError {
  display: inline-block;
  padding-left: 32px;
}

.form {
  position: relative;
  background: #f5f2f0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.checkbox {
  padding-left: 0;
  text-wrap: wrap;
  display: inline;
  align-items: flex-start;
  font-weight: 400;
  font-family: Oxygen;
  text-align: left;
}

.checkbox span {
  font-size: 14px;
  text-wrap: wrap;
}

.checkbox span:first-of-type {
  margin-top: 2px;
  min-width: 22px;
}

.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 22px;
  margin-bottom: 10px;
}

.xButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.background {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 38px;
  width: 100%;
  height: 100%;
}

.header {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  font-family: DMSerifDisplay;
  text-align: center;
}

.paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: Oxygen;
  text-align: center;
}

.orContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.line {
  flex: 1;
  background-color: #000000;
  height: 1px;
}

.orText {
  padding: 0 10px;
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  font-family: Oxygen;
  letter-spacing: 0.1em;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 14px;
  font-family: Oxygen;
}

.input {
  width: 100%;
}

.inputExtraPadding {
  margin-top: 8px;
  margin-bottom: 8px;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.submitButton > picture {
  transform: translateY(2px);
}

.footer {
  background: #000000;
  padding: 16px 0px 16px 0px;
  width: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Oxygen;
  text-align: center;
}

.footer > button {
  display: inline;
  cursor: pointer;
  margin: 0;
  border: none;
  background: unset;
  padding: 0;
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Oxygen;
  text-align: center;
  text-align: center;
  text-decoration: underline;
}

.socialButton {
  margin-bottom: 10px;
}

.inputDescription {
  display: inline-block;
  margin-top: 4px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Oxygen;
  text-align: left;
}

/* this is the css for the signup part of register form */

.signupBackground label {
  margin-top: 20px;
}

.signupEmailEnterFrom {
  transform: translateX(100%);
}

.signupEmailEnterTo {
  transform: translateX(0);
}

.signupEmailEnter {
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 300ms;
  transition-property: transform;
  transition-timing-function: linear;
}

.signupEmailLeaveFrom {
  transform: translateX(0);
}

.signupEmailLeaveTo {
  transform: translateX(100%);
}

.signupEnterFrom {
  transform: translateX(-100%);
}

.signupEnterTo {
  transform: translateX(0%);
}

.signupLeaveFrom {
  transform: translateX(0%);
}

.signupLeaveTo {
  transform: translateX(-100%);
}

.datePickerDescription {
  margin-top: 20px;
}

.datePickerButton {
  border: 1px solid #d9d9d9;
}

button.backButton {
  position: absolute;
  top: 8px;
  left: 6px;
  margin: 0;
  border: none;
  background: transparent;
  padding: 10px 12px;
}
