@value modalOverlayZIndex from "@/styles/variables.module.css";

.background {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  z-index: modalOverlayZIndex;
  transition: opacity 0.3s ease-in-out;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0s 0.3s;
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.backgroundVisible {
  visibility: visible;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}
